.main-sidebar {
    background: #f8f9fa;
    z-index: 1070;
    will-change: transform;
    transition: -webkit-transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
}

.main-sidebar.open {
    height: calc(100%);
    transform: translateX(0);
    box-shadow: 0 .125rem 9.375rem rgba(90, 97, 105, .1),
    0 .25rem .5rem rgba(90, 97, 105, .12),
    0 .9375rem 1.375rem rgba(90, 97, 105, .1),
    0 .4375rem 2.1875rem rgba(165, 182, 201, .1);
}

.main-sidebar .toggle-sidebar {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 1.25rem;
    font-size: 1.25rem;
    border-left: 1px solid #e1e5eb
}

.main-sidebar .toggle-sidebar:hover {
    cursor: pointer
}

.main-sidebar .navbar-brand {
    overflow: hidden;
    height: 3.75rem;
    font-size: 1rem
}

.main-sidebar .nav-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 3.75rem - 1px)
}

.main-sidebar .logo-link .logo-title {
    font-weight: 600;
    position: relative;
    color: #3D516F;
}

button.navbar-toggler:focus {
    outline: none;
}

.main-sidebar .menu-wallet {
    height: calc(100% - 64px);
    min-height: calc(100vh - 64px);
    overflow-y: scroll;
}

.main-sidebar .nav-item .logo-link.nav-link:hover {
    box-shadow: none!important;
}

.main-sidebar .nav-item,
.main-sidebar .nav-link {
    white-space: nowrap;
    min-width: 100%;
    max-width: 100%;
    text-overflow: ellipsis;
    will-change: background-color, box-shadow, color;
    transition: box-shadow .2s ease, color .2s ease, background-color .2s ease;
    font-size: .85rem
}

.main-sidebar .nav-item .nav-link {
    border-bottom: 1px solid #e1e5eb;
    font-weight: 400;
    color: #3d5170;
    padding: 0.5rem 1rem;
}

.main-sidebar .nav-item:nth-last-child(2) .nav-link {
    border-bottom: none;
}

.main-sidebar .nav-item-footer .nav-link {
    border-bottom: none;
    height: calc(3rem + 1px);
}

.main-sidebar .nav-item .nav-link i {
    min-width: 1.25rem;
    font-size: 90%;
    text-align: center;
    vertical-align: middle;
    will-change: color;
    color: #cacedb;
    transition: color .2s ease;
    margin-right: .375rem
}

.main-sidebar .nav-item .nav-link i.material-icons {
    font-size: 1.125rem;
    top: -1px
}

.main-sidebar .nav-item .nav-link.active,
.main-sidebar .nav-item .nav-link:hover,
.main-sidebar .nav-item.active,
.main-sidebar .nav-item:hover {
    box-shadow: inset .1875rem 0 0 #007bff;
    background-color: #fbfbfb;
    color: #007bff
}

.main-sidebar .nav-item .nav-link.active i,
.main-sidebar .nav-item .nav-link:hover i,
.main-sidebar .nav-item.active i,
.main-sidebar .nav-item:hover i {
    color: #007bff
}

.main-sidebar .nav--no-borders .nav-item .nav-link {
    border-bottom: 0
}

.main-sidebar .nav-item .nav-link .contract-image {
    line-height: 2rem;
    vertical-align: middle;
}

.main-sidebar .nav-item .nav-link .contract-image img {
    width: 2rem;
}

.main-sidebar .nav-item .nav-link .contract-info .token-action {
    right: 0;
    top: 0.25rem;
    cursor: pointer;
}

.main-sidebar .nav-item .nav-link .contract-info .token-action img {
    width: 0.65rem;
}

.main-sidebar .nav--no-borders .dropdown-menu {
    box-shadow: inset 0 0 .4375rem rgba(61, 81, 112, .2)
}

.main-sidebar .nav--no-borders .dropdown-menu .dropdown-item:first-child {
    border-top: 1px solid #e1e5eb
}

.main-sidebar .dropdown-menu {
    position: static !important;
    -webkit-transform: translate(0) !important;
    transform: translate(0) !important;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    border: none;
    padding: 0;
    box-shadow: inset 0 -.1875rem .1875rem rgba(61, 81, 112, .08)
}

.main-sidebar .dropdown-menu .dropdown-item {
    padding: .75rem 1.75rem;
    border-bottom: 1px solid #f0f2f5;
    color: #3d5170;
    font-size: .8125rem;
    font-weight: 400
}

.main-sidebar .dropdown-menu .dropdown-item.active, .main-sidebar .dropdown-menu .dropdown-item:hover {
    color: #007bff
}

.main-sidebar .dropdown-menu .dropdown-item:hover {
    background: 0 0
}

.main-sidebar .dropdown-menu .dropdown-item.active {
    background-color: #fbfbfb
}

.main-sidebar .dropdown-menu .dropdown-item:last-of-type {
    border-bottom: 1px solid #e1e5eb
}

.main-sidebar .dropdown-menu .dropdown-divider {
    margin: 0
}

.main-sidebar .dropdown-toggle {
    position: relative
}

.main-sidebar .dropdown-toggle::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+);
    background-position: center center;
    width: .875rem;
    height: .5625rem;
    transition: -webkit-transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
    border: none;
    position: absolute;
    top: 50%;
    right: .625rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: .1;
    will-change: transform
}

.main-sidebar .dropdown.show .dropdown-toggle::after {
    -webkit-transform: translateY(-50%) rotateZ(180deg);
    transform: translateY(-50%) rotateZ(180deg)
}

@media only screen and (max-width: 734px) {
    .main-sidebar {
        border-top: 1px solid #e1e5eb;
        position: fixed;
        bottom: 2.35rem;
    }

    .main-sidebar.open {
        bottom: inherit;
    }

    .main-sidebar .menu-wallet {
        height: calc(100vh - 64px);
    }
}

.modal-token-info > div {
    flex: 0.5;
    flex-basis: 50%;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    padding: 0 0.25rem;
    margin-bottom: 0.5rem;
}

.modal-token-info .token-info-header {
    background: #3d516f;
    padding: 0.25rem;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 0.25rem;
}
