.curved-menu {
    z-index: 1001;
}

.navbar {
    background: linear-gradient(0deg, #E3EDF7, #E3EDF7);
    justify-content: space-between;
}

.navbar .logo-link {
    padding: 0;
}

.navbar .logo-link img {
    width: 32px;
    height: 32px;
}

.navbar .logo-link .logo-title {
    font-weight: 600;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

.navbar .logo-link .nav-embellish {
    font-size: 72.5%;
    font-weight: 700;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

.navbar .navbar-toggler {
    border: 0;
}

.navbar .navbar-toggler:focus {
    outline: none;
}

.navbar .navbar-toggler img {
    width: 50%;
}

.curved-menu {
    z-index: 1001;
}

.curved-menu .nav-link {
    font-size: 1rem;
    font-weight: 700;
    color: #3d516f!important;
}

.curved-menu .nav-link.active {
    color: #3d516f!important;
}

.curved-menu .separator {
    border-top: 1px solid #3d516f67;
}

.community-menu .list-group,
.products-menu .list-group {
    box-shadow: none;
}

.community-menu .list-group-item,
.products-menu .list-group-item {
    border: none;
    background: transparent;
    text-align: left;
    position: relative;
}

.community-menu .dropdown-item.active,
.community-menu .dropdown-item:active,
.products-menu .dropdown-item.active,
.products-menu .dropdown-item:active {
    color: #3D516F;
    text-decoration: none;
    background-color: transparent;
}

.community-menu .dropdown-item:hover,
.community-menu .dropdown-item:focus,
.products-menu .dropdown-item:hover,
.products-menu .dropdown-item:focus {
    color: #3D516F;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    border: none;
}

.community-menu .nav-link img {
    width: 1rem;
    height: 1rem;
}

.community-menu .nav-link span.social-text {
    font-size: 0.75rem;
    font-weight: 400;
}

footer {
    border-top: 1px solid #e1e5eb;
    width: 100%;
    height: 2.5rem;
    position: fixed;
    bottom: 0;
    z-index: 1000;
}

.footer-menu {
    z-index: 1001;
}

.footer-menu .nav-link {
    font-size: 1rem;
    font-weight: 700;
}

.footer-menu .nav-link.active {
    color: #3D516F!important;
}

.footer-menu .nav-link img {
    width: 1rem;
    height: 1rem;
}

@media only screen and (min-width: 768px) {
    .footer-menu .activity-menu {
        margin-right: 25%;
    }
}

@media only screen and (min-width: 992px) {
    .footer-menu .activity-menu {
        margin-right: 16.666667%;
    }
}

@media only screen and (max-width: 734px) {
    footer.expanded .footer-menu button.navbar-toggler {
        position: absolute;
    }

    .footer-menu .navbar-nav {
        display: flex;
        flex-direction: row;
    }

    .footer-menu .nav-link {
        flex: 0.25;
        padding: 0.5rem;
    }

    footer {
        z-index: 9999;
    }
}

@media (max-width: 576px) {
    .dropdown-toggle::after {
        display: none!important;
    }

    .community-menu,
    .products-menu {
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

    .community-menu .dropdown-item,
    .products-menu .dropdown-item {
        flex-direction: column;
        padding: 0!important;
    }

    .products-menu .dropdown-item .list-group .list-group-item {
        padding-top: 0!important;
        padding-right: 0!important;
        padding-left: 0!important;
    }

    .products-menu .dropdown-item .list-group-item-heading {
        display: none;
    }

    .community-menu .form-group {
        margin-top: 0.25rem!important;
        margin-bottom: 0.5rem!important;
    }
}
